import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { MultiColumnCTALinkCard } from 'src/components/ContentPageCards/MultiColumnCTALinkCard';
import { FullWidthCTAButtonCard } from 'src/components/ContentPageCards/FullWidthCTAButtonCard';
import {
  ChevronRight,
  SchoolOutlined,
  TodayOutlined,
  GroupsOutlined,
  LibraryBooksOutlined,
  LocalLibraryOutlined
} from '@mui/icons-material';
import LogoImg from 'src/assets/racgp-gplearning-logo.svg';
import AJGPLogo from 'src/assets/AJGP_Logo.svg';

import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';

const GPLearningURL = import.meta.env.VITE_GP_LEARNING_LINK;
const ResourcesPage = () => {
  return (
    <>
      <ContentPageTitle currentPageTitle="Resources" />

      <Container maxWidth="lg">
        <Typography variant="body2" sx={{ mt: 3 }}>
          CPD resources across all activity types including access to gplearning, RACGP CPD
          Solutions, RACGP events and conferences, guides and templates and a wide variety of
          reading material.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <FullWidthCTAButtonCard
            image={{ url: LogoImg, styles: { maxHeight: 34, width: 229.36 } }}
            button={{
              cta: 'Explore gplearning',
              link: { url: GPLearningURL, target: '_blank' }
            }}
            description="Explore our gplearning platform for access to hundreds of courses and online activities.​​"
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="RACGP CPD Solutions"
              description="The RACGP has prepared multiple solutions based on scopes of practice incorporating recommendations on meeting your annual CPD hours."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/FSDEDEV/media/documents/CPD/RACGP-CPD-Solutions.pdf',
                  target: '_blank'
                }
              }}
              CardIcon={SchoolOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="RACGP Events"
              description="Find events, webinars (live and on-demand), member meet-ups, podcasts, digital workshops and Faculty events."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/racgp-digital-events-calendar/home',
                  target: '_blank'
                }
              }}
              CardIcon={TodayOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="RACGP Guides & Templates"
              description=" The RACGP has developed CPD activity guides and templates to support you in meeting your
              CPD program requirements."
              button={{
                cta: 'View more',
                link: {
                  url: '/guides-templates',
                  target: ''
                }
              }}
              CardIcon={GroupsOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="Australia Journal of General Practice"
              description="The official journal of the RACGP providing relevant, evidence-based and topical information."
              button={{
                cta: 'View more',

                link: {
                  url: 'https://www1.racgp.org.au/ajgp/home',
                  target: '_blank'
                }
              }}
              CardImage={{
                url: AJGPLogo,
                styles: { height: '1.4rem', width: 'fit-content', marginBottom: '26px' }
              }}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="Clinical Guidelines"
              description="Guidelines for a range of topics including preventive health, diabetes and osteoarthritis."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/clinical-resources/clinical-guidelines/guidelines-by-topic',
                  target: '_blank'
                }
              }}
              CardIcon={LibraryBooksOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="Curriculum and syllabus"
              description="The RACGP’s curriculum and syllabus for Australian General Practice describes the key competency and learning outcomes of GP education."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/education/education-providers/curriculum/curriculum-and-syllabus/home',
                  target: '_blank'
                }
              }}
              CardIcon={GroupsOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>

          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="Conferences"
              description="Find out about upcoming RACGP conferences."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/education/professional-development/conferences',
                  target: '_blank'
                }
              }}
              CardIcon={SchoolOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="Rural and remote"
              description="To reach rural and remote GPs, RACGP Rural delivers educational activities, including a wide range of workshops held outside of capital cities."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/the-racgp/faculties/rural/education-and-training/cpd-opportunities',
                  target: '_blank'
                }
              }}
              CardIcon={TodayOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
          <Grid item md={4} sx={{ display: 'flex', width: '100%' }}>
            <MultiColumnCTALinkCard
              heading="John Murtagh Library"
              description="An extensive collection of specialist General Practice related resources including access to Dynamed – a clinical decision support mechanism."
              button={{
                cta: 'View more',
                link: {
                  url: 'https://www.racgp.org.au/clinical-resources/john-murtagh-library',
                  target: '_blank'
                }
              }}
              CardIcon={LocalLibraryOutlined}
              ButtonIcon={ChevronRight}
              IsNew={false}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export { ResourcesPage };
